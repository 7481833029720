import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const vingtiemenomade = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Vingtième Nomade" />
    <h3 className='underline-title'>Vingtième Nomade</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Ils ricanent parce que je ne passe<br />
        Ni par Salamanque ni par Sorbonne<br />
        Ils s'étonnent parce que je passe dessus<br />
        Classicisme assis Parnasse altier<br />
        Ils ne comprennent pas parce que je prie <br />
        Ni en Cathédrale ni en Synagogue<br />
      </p>
      <p>
        Dès premier son d'anglélus<br />
        Ils me voient simplement en station<br />
        N'importe où n'importe quand<br />
        Sans ablutions musulmanes<br />
        Rendre à la vie bohémienne<br />
        L'angélus sonore de l'instant<br />
      </p>
      <p>
        Ils devraient savoir<br />
        Et non s'effarer ni ricaner:<br />
        Les dieux ubiquistes flânent<br />
        Ils déambulent à leur bon gré<br />
        On les croise inspirant<br />
        À chaque courbe du fleuve<br />
        À chaque couche d'air <br />
      </p>
      <p>
        Occupez-vous de vos orgies <br />
        Ne cherchez pas où je vais <br />
        Ne regardez pas l'heure qu'il fait <br />
        Je prends un sentier quelconque <br />
        Je décampe à l'heure imprévue <br />
        Je vais partout et nulle part <br />
        Je suis donc du XX<sup>e</sup> Nomade. <br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres/">Table des matières</Link>
  </Layout>
)

export default vingtiemenomade
